/* 
CUSTOM CSS FOR INSTANT INVOCIE
***
To override any css or to add custom css.
***
*/

.ii-main-content {
    padding: 5px 5px;
    min-height: calc(100vh - 136px);
  }

.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 0px;
    border: 1px solid transparent;
}

.navbar .navbar-nav > li > a {
    padding: 10px 15px;
    margin: 15px 3px;
    position: relative; 
}
.customUserLabel {
    /* margin-right: 0; */
    float: right!important;
    margin-top: 22px;
    color: #0b5186;
    /* margin: 0; */
    /* padding-left: 0; */

}

.MuiFormLabel-root.Mui-error {
    font-size: 17px !important;
    line-height: 1.42857 !important;
}

.radio input[type="radio"]:checked:not(:disabled):hover + label::after,
.radio input[type="radio"]:checked + label::after {
  color: #0075c1; 
}

.react-tagsinput-input{
    color: #565656;
    width: 100% !important;
    font-size: 14px !important;
}
.modal-dialog-custom{
    width: 1000px !important;
}

.modal-dialog-custom2{
    width: 800px !important;
}

.modal-dialog-email-statistics-custom{
    width: 1200px !important;
}

.modal-dialog-payment-custom{
    width: 450px !important;
}
.modal-dialog-timeout-custom{
    width: 400px !important;
}
.modal-header .close {
    display:none;
  }

#CreateInvoiceSplitButton{    
    color: #ffffff;
    background-color: #0075c1;
}

@media print {
    .page-break {page-break-after: always;}
  }

/*
#CreateAndSendInvoice{
    color: #ffffff;
    background-color: #0075c1;
    margin-top: 0px;
} */
#CreateAndSendInvoice:hover {
    color: #ffffff;
    background-color:  #0066a8;
}
/* .dropdown-menu{
    background-color: #0075c1;
    border-radius: 4px;
    text-align: center;
    height: 40px;
}
.dropdown-menu:hover {
    background-color:  #0066a8;
}*/
.btn-primary .caret{
    color: #ffffff;
    border-top-color:#FFF;
} 

/* .progress-bar, */
.progress-bar-blue {
  background-color: rgb(0, 143, 251);
}
.progress-bar-purple {
    background-color: rgb(119, 93, 208);
}
.progress {
    background-color: #e5e5e5;
    border-radius: 3px;
    box-shadow: none;
    height: 4px;
}
.sidebar .nav p {
    color: #0b5186;    
}
.sidebar-mini .sidebar {
    z-index: 5; 
}
.MuiCheckbox-root {
    color: #e5e5e5 !important;
}
/* .MuiCheckbox-colorSecondary.Mui-checked {
    color: #e5e5e5 !important;
} */

.MuiIconButton-colorPrimary{
    color: #0075C1 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked{
    color: #0075C1 !important;
}

 .invoicelink:hover,
 .invoicelink:focus {   
    text-decoration: underline;
    color: #0b5186;
 }
 .invoicelink {    
    color: #0075c1;
    opacity: 1;
    text-decoration: none;
  }
  .MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #ffffff !important;
  }
  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon{
    color: #ffffff !important;
  }
  .material-icons MuiIcon-root MuiTableSortLabel-icon MuiTableSortLabel-iconDirectionAsc{
    color: #ffffff !important;
  }
  .MuiButtonBase-root.MuiTableSortLabel-root{
    color: #ffffff !important;
  }
  .input-group .form-control {
      z-index: 0 !important;
  }

  trix-toolbar .trix-button-group--file-tools {
    display: none;
  }
  /* .css-2613qy-menu{
      background-color: red !important;
      z-index: 44 !important;
  } */
  /* .MuiTableSortLabel-icon{
      opacity: 1 !important;
  } */

 /* .formInner {
    font-family: 'Abel' !important;
    width: 500px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px auto;
}

#payButton {
    width: 290px;
    display: block;
    margin: 20px auto;
    height: 50px !important;
    font-size: 20px;
    background-color: #1AD18E;
    border-color: #1AD18E;
    box-shadow: 0 3px 10px #bbbbbb;
}

#payButton:hover {
    background-color: #19C687;
    border-color: #19C687;
    box-shadow: 0 3px 4px #bbbbbb;
}

#payButton:active {
    opacity: 0.7;
}

.checkboxLabel {
    margin: 0 0 0 7px;
}

.payment-field {
    border-radius: 2px;
    width: 48%;
    margin-bottom: 14px;
    box-shadow: 0 2px 8px #dddddd;
    font-size: 16px;
    transition: 200ms;
}

.payment-field input:focus {
    border: 3px solid #1AD18E;
    outline: none !important;
}

.payment-field:hover {
    box-shadow: 0 2px 4px #dddddd;
}

.payment-field input {
    border: 3px solid #ffffff;
    width: 100%;
    border-radius: 2px;
    padding: 4px 8px;
}

#payment-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
} */

/* .data-placeholder-css {
    background-color:"#808080";
    color: green
} */

/* #ccnumber {   
    font-family: sans-serif;
    font-size: 18px;    
} */

/* #ccexp,
#cvv {
    width: 50%;
    font-size: 20px;
} */
/*
#paymentTokenInfo {
    width: 600px;
    display: block;
    margin: 30px auto;
}

.separator {
    margin-top: 30px;
    width: 100%;
}  */
