.progress {
  background-color: #e5e5e5;
  border-radius: 3px;
  box-shadow: none;
  height: 4px;
}
.progress-thin {
  height: 2px;
}

.progress-bar,
.progress-bar-primary {
  background-color: $primary-color;
}
.progress-bar-info {
  background-color: $info-color;
}
.progress-bar-success {
  background-color: $success-color;
}
.progress-bar-warning {
  background-color: $warning-color;
}
.progress-bar-danger {
  background-color: $danger-color;
}
